





























 import { Page, Sort, Filter, Edit, Toolbar,Resize,ColumnChooser  } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
Vue.use(GridPlugin);
@Component({
     provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar,Resize,ColumnChooser ]
  }

})
export default class Order extends Vue {
    dataManager = MyGridOption.getDataManager('SalesOrderList');
    filterOptions = {    type: 'Excel'};
    wrapSettings = { wrapMode: 'Content' };
    toolbarOptions = ['ColumnChooser'];
}
